import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'

// Constants
export const constants = {
  FETCH: 'FILE/FETCH',
  FETCH_DOWNLOAD: 'FILE/FETCH_DOWNLOAD',
  FETCH_FAILURE: 'FILE/FETCH_FAILURE',
  RECEIVE_DOCUMENT_TYPES: 'FILE/RECEIVE_DOCUMENT_TYPES',
  RECEIVE_DEVICE_DOCUMENT_TYPES: 'FILE/RECEIVE_DEVICE_DOCUMENT_TYPES',
  RECEIVE_UPLOAD_DOCUMENT_TYPES: 'FILE/RECEIVE_UPLOAD_DOCUMENT_TYPES',
  RECEIVE_DOCUMENT: 'FILE/RECEIVE_DOCUMENT',
  OPEN_UPLOAD_FILE_FORM: 'FILE/OPEN_UPLOAD_FILE_FORM',
  CLOSE_UPLOAD_FILE_FORM: 'FILE/CLOSE_UPLOAD_FILE_FORM',
  SET_UPLOADING_STATE: 'FILE/SET_UPLOADING_STATE',
  DOWNLOAD_FAIL: 'FILE/DOWNLOAD_FAIL',
  SHOW_FIELDS_FOR_UPLOADING_FILE_DEVICE_TYPE: 'FILE/SHOW_FIELDS_FOR_UPLOADING_FILE_DEVICE_TYPE',
  DEVICE_TYPES_SUCCESS: 'FILE/DEVICE_TYPES_SUCCESS',
  DEVICE_TYPES_FAILURE: 'FILE/DEVICE_TYPES_FAILURE',
  FETCH_FILTER_FAILURE: 'FILE/FETCH_FILTER_FAILURE',
  FETCH_FILTER_TYPES: 'FILE/FETCH_FILTER_TYPES',
  EDIT_FILE_RECORD_SUCCESS: 'FILE/EDIT_FILE_RECORD_SUCCESS',
  EDIT_FILE_RECORD_FAILURE: 'FILE/EDIT_FILE_RECORD_FAILURE',
  RESET_EDIT_FILE_STATUS: 'FILE/RESET_EDIT_FILE_STATUS',
  LOAD_LANGUAGES_SUCCESS: 'FILE/LOAD_LANGUAGES_SUCCESS',
  LOAD_LANGUAGES_FAILURE: 'FILE/LOAD_LANGUAGES_FAILURE',
  LOAD_VALIDFILETYPES_SUCCESS: 'FILE/LOAD_VALIDFILETYPES_SUCCESS',
  LOAD_VALIDFILETYPES_FAILURE: 'FILE/LOAD_VALIDFILETYPES_FAILURE',
  EDIT_FILE_RECORD: 'FILE/EDIT_FILE_RECORD',
  ADD_EDIT_FILE_SUCCESS: 'FILE/ADD_EDIT_FILE_SUCCESS',
  RESET_ADD_EDIT_FILE_SUCCESS: 'FILE/RESET_ADD_EDIT_FILE_SUCCESS',
  DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
  DELETE_FILE_FAILURE: 'DELETE_FILE_FAILURE',
  CREATE_FILE_MGMT_SESSION_SUCCESS: 'CREATE_FILE_MGMT_SESSION_SUCCESS',
  CREATE_FILE_MGMT_SESSION_FAILURE: 'CREATE_FILE_MGMT_SESSION_FAILURE',
  REPLACE_FILE_CONFIRMATION: 'REPLACE_FILE_CONFIRMATION',
  FETCH_UPLOAD_SESSION: 'FETCH_UPLOAD_SESSION',
  GENERATE_CONFIG_FILE_SUCCESS: 'GENERATE_CONFIG_FILE_SUCCESS',
  GENERATE_CONFIG_FILE_FAILURE: 'GENERATE_CONFIG_FILE_FAILURE',
  FILE_UPLOAD_CONFIRMATION_MODAL_STATE: 'FILE_UPLOAD_CONFIRMATION_MODAL_STATE',
  FETCH_DOCUMENT_TYPE_ID_SUCCESS: 'FETCH_DOCUMENT_TYPE_ID_SUCCESS',
  FETCH_DOCUMENT_TYPE_ID_FAILURE: 'FETCH_DOCUMENT_TYPE_ID_FAILURE'
}

export const resolveStream = ({ data, headers }) => {
  var cd = headers.get('content-disposition')
  let fileName = `${cd.split('=')[2]}`.replace(/["']/g, '')
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(data, fileName)
  } else {
    let url = window.URL.createObjectURL(data)
    var element = document.createElement('a')
    document.body.appendChild(element)
    element.style = 'display: none'
    element.href = url
    element.download = fileName
    element.click()
    document.body.removeChild(element)
    window.URL.revokeObjectURL(url)
  }
}

// Action Creators
export const actions = {
  fetchDocumentTypes({ deviceTypes, documentTypes }) {
    return (dispatch, getstate) => {
      dispatch({ type: constants.RECEIVE_DOCUMENT_TYPES, data: documentTypes })
      dispatch({ type: constants.DEVICE_TYPES_SUCCESS, deviceTypes })
    }
  },
  fetchDeviceDocumentTypes() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_FILTER_TYPES }),
      apiFunction: 'fetchDeviceDocumentTypes',
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_DEVICE_DOCUMENT_TYPES, data }),
      onFailure: (error) => ({ type: constants.FETCH_FILTER_FAILURE, error })
    })
  },
  fetchUploadDocumentTypes(categoryValue) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_FILTER_TYPES }),
      args: { fileCategory: categoryValue },
      apiFunction: 'fetchUploadDocumentTypes',
      onSuccess: ({ data }) => ({ type: constants.RECEIVE_UPLOAD_DOCUMENT_TYPES, data }),
      onFailure: (error) => ({ type: constants.FETCH_FILTER_FAILURE, error })
    })
  },
  getdownloadFileArgs(getState, documentId) {
    return ({ documentId })
  },
  downloadFile(documentId) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_DOWNLOAD }),
      apiFunction: 'downloadFile',
      args: (getState) => actions.getdownloadFileArgs(getState, documentId),
      onSuccess: resolveStream,
      onFailure: (error) => ({ type: constants.DOWNLOAD_FAIL, error: error.message })
    })
  },
  openUploadFileForm() {
    return (dispatch) => {
      dispatch({ type: constants.OPEN_UPLOAD_FILE_FORM })
    }
  },
  closeUploadFileForm() {
    return (dispatch) => {
      dispatch({ type: constants.CLOSE_UPLOAD_FILE_FORM })
    }
  },
  setUploadingState(uploadStatus) {
    return { type: constants.SET_UPLOADING_STATE, uploadStatus }
  },
  showFieldsForUploading() {
    return { type: constants.SHOW_FIELDS_FOR_UPLOADING_FILE_DEVICE_TYPE }
  },
  fetchDeviceTypes() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_FILTER_TYPES }),
      apiFunction: 'getAllDeviceTypes',
      onSuccess: ({ data }) => ({ type: constants.DEVICE_TYPES_SUCCESS, deviceTypes: data }),
      onFailure: (error) => ({ type: constants.FETCH_FILTER_FAILURE, error: error })
    })
  },
  editFileRecord(args) {
    return safeFetch({
      onFetch: () => ({ type: constants.EDIT_FILE_RECORD }),
      apiFunction: 'editFile',
      args: args,
      onSuccess: ({ data }) => ({ type: constants.EDIT_FILE_RECORD_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.EDIT_FILE_RECORD_FAILURE, error: error.message }),
      throwError: true
    })
  },
  resetEditFileStatus() {
    return { type: constants.RESET_EDIT_FILE_STATUS }
  },
  fetchAllLanguages() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      apiFunction: 'docRelatedLanguages',
      onSuccess: ({ data }) => ({ type: constants.LOAD_LANGUAGES_SUCCESS, data }),
      onFailure: ({ status }) => ({ type: constants.LOAD_LANGUAGES_FAILURE, status })
    })
  },
  getValidFileTypes(param) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      args: param,
      apiFunction: 'geValidFileTypesFileManagement',
      onSuccess: ({ data }) => ({ type: constants.LOAD_VALIDFILETYPES_SUCCESS, data }),
      onFailure: ({ status }) => ({ type: constants.LOAD_VALIDFILETYPES_FAILURE, status })
    })
  },
  fileAddEditSuccess(status) {
    return { type: constants.ADD_EDIT_FILE_SUCCESS, status }
  },
  resetFileAddEditSuccess() {
    return { type: constants.RESET_ADD_EDIT_FILE_SUCCESS }
  },
  deleteFile(documentId, docName) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      args: { documentId },
      apiFunction: 'removeItem',
      onSuccess: ({ data }) => ({ type: constants.DELETE_FILE_SUCCESS, data, docName }),
      onFailure: ({ status }) => ({ type: constants.DELETE_FILE_FAILURE, status })
    })
  },
  createSessionFileMgmt(createSessionData, fileUploadData) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_UPLOAD_SESSION }),
      args: createSessionData,
      apiFunction: 'createSessionFileMgmt',
      onSuccess: ({ data }) => ({ type: constants.CREATE_FILE_MGMT_SESSION_SUCCESS, data, createSessionData, fileUploadData }),
      onFailure: ({ status }) => ({ type: constants.CREATE_FILE_MGMT_SESSION_FAILURE, status })
    })
  },
  replaceFile(isConfirmed) {
    return { type: constants.REPLACE_FILE_CONFIRMATION, isConfirmed }
  },
  toggleUploadConfirmation(status) {
    return { type: constants.FILE_UPLOAD_CONFIRMATION_MODAL_STATE, status }
  },
  generateConfigFile(deviceIds) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      args: deviceIds,
      apiFunction: 'generateConfigFile',
      onSuccess: ({ data }) => ({ type: constants.GENERATE_CONFIG_FILE_SUCCESS, data }),
      onFailure: ({ status }) => ({ type: constants.GENERATE_CONFIG_FILE_FAILURE, status })
    })
  },
  fetchDocumentTypeId(documentTypeName) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH }),
      args: { documentTypeName },
      apiFunction: 'fetchDocumentTypeId',
      onSuccess: ({ data }) => ({ type: constants.FETCH_DOCUMENT_TYPE_ID_SUCCESS, data }),
      onFailure: ({ status }) => ({ type: constants.FETCH_DOCUMENT_TYPE_ID_FAILURE, status })
    })
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  openUploadFileForm: false,
  documentTypes: [],
  uploading: false,
  downloadError: null,
  showDeviceTypeFields: false,
  fetchFilterError: null,
  devicedocumentTypes: [],
  editFileSuccess: null,
  languages: [],
  validFileTypes: [],
  fileFetching: false,
  addEditFileStatus: null,
  deleteFileSuccess: false,
  fileUploadData: {},
  isFileReplaceConfirmed: false,
  sessionCreated: false,
  isDuplicateFile: false,
  deleteFileFailure: null,
  fetchingAddFileStatus: false,
  isConfirmationModalVisible: false,
  deletedFileName: null,
  isConfigFileGenerated: false,
  genConfigFileError: null,
  documentTypeObj: {}
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .set('fetching', true)
        .set('downloadError', null)
        .set('uploadFileSuccess', null)
        .set('fileUploadData', {})
        .set('deleteFileSuccess', false)
        .set('deleteFileFailure', null)
        .set('deletedFileName', null)
        .set('isConfigFileGenerated', false)
        .set('genConfigFileError', null)
        .set('documentTypeObj', {})
    case constants.FETCH_DOWNLOAD:
      return state
        .set('fetching', false)
        .set('downloadError', null)
        .set('uploadFileSuccess', null)
    case constants.FETCH_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.DOWNLOAD_FAIL:
      return state
        .set('downloadError', action.error)
    case constants.FETCH_FILTER_TYPES:
      return state
        .set('fetchingFilters', true)
        .set('fetching', false)
        .set('downloadError', null)
        .set('uploadFileSuccess', null)
        .set('addEditFileStatus', null)
    case constants.RECEIVE_DOCUMENT_TYPES:
      return state
        .set('fetchingFilters', false)
        .set('documentTypes', fromJS(action.data))
        .set('fetching', false)
        .set('uploadFileSuccess', null)
        .set('docTypefilterCategories', formatDocTypeFilterCategories(action.data))
        .set('addEditFileStatus', null)
    case constants.RECEIVE_DEVICE_DOCUMENT_TYPES:
      return state
        .set('fetchingFilters', false)
        .set('devicedocumentTypes', fromJS(action.data))
        .set('fetching', false)
        .set('addEditFileStatus', null)
        .set('docTypefilterCategories', formatDocTypeFilterCategories(action.data))
    case constants.RECEIVE_UPLOAD_DOCUMENT_TYPES:
      return state
        .set('fetchingFilters', false)
        .set('uploaddocumentTypes', fromJS(action.data))
        .set('fetching', false)
        .set('uploadFileSuccess', null)
    case constants.FETCH_FILTER_FAILURE:
      return state
        .set('fetchFilterError', action.error)
        .set('fetching', false)
        .set('fetchingFilters', false)
    case constants.OPEN_UPLOAD_FILE_FORM:
      return state
        .set('openUploadFileForm', true)
    case constants.CLOSE_UPLOAD_FILE_FORM:
      return state
        .set('openUploadFileForm', false)
    case constants.SET_UPLOADING_STATE:
      return state
        .set('uploading', action.uploadStatus)
    case constants.SHOW_FIELDS_FOR_UPLOADING_FILE_DEVICE_TYPE:
      return state
        .set('showDeviceTypeFields', true)
    case constants.DEVICE_TYPES_SUCCESS:
      return state
        .set('fetchingFilters', false)
        .set('deviceTypes', fromJS(action.deviceTypes))
        .set('fetching', false)
        .set('deviceTypefilterCategories', formatDeviceTypeFilterCategories(action.deviceTypes))
    case constants.EDIT_FILE_RECORD:
      return state
        .set('fileFetching', true)
    case constants.EDIT_FILE_RECORD_SUCCESS:
      return state
        .set('editFileSuccess', true)
        .set('fileFetching', false)
    case constants.EDIT_FILE_RECORD_FAILURE:
      return state
        .set('fileFetching', false)
    case constants.RESET_EDIT_FILE_STATUS:
      return state
        .set('editFileSuccess', null)
    case constants.LOAD_LANGUAGES_SUCCESS:
      return state
        .set('languages', fromJS(action.data))
    case constants.LOAD_VALIDFILETYPES_SUCCESS:
      return state
        .set('fetching', false)
        .set('validFileTypes', fromJS(action.data))
    case constants.ADD_EDIT_FILE_SUCCESS:
      return state
        .set('fetching', false)
        .set('addEditFileStatus', action.status)
    case constants.RESET_ADD_EDIT_FILE_SUCCESS:
      return state
        .set('fetching', false)
        .set('addEditFileStatus', null)
    case constants.DELETE_FILE_SUCCESS:
      return state
        .set('deleteFileSuccess', true)
        .set('deletedFileName', action?.docName)
    case constants.DELETE_FILE_FAILURE:
      return state
        .set('deleteFileFailure', action.error)
    case constants.FETCH_UPLOAD_SESSION:
      return state
        .set('fetchingAddFileStatus', true)
    case constants.CREATE_FILE_MGMT_SESSION_SUCCESS:
      const { operation } = action?.createSessionData
      return state
        .set('sessionCreated', operation === 'create_session' ? true : false)
        .set('isDuplicateFile', operation === 'create_session' ? action?.data?.fileExist : false)
        .set('fileUploadData', operation === 'create_session' ? action?.fileUploadData : {})
        .set('fetchingAddFileStatus', operation === 'create_session' && action?.data?.fileExist ? false : true)
        .set('fetchingAddFileStatus', operation === 'clear_session' && false)
        .set('isFileReplaceConfirmed', operation === 'clear_session' && false)
    case constants.CREATE_FILE_MGMT_SESSION_FAILURE:
      return state
        .set('isDuplicateFile', false)
        .set('fileUploadData', {})
        .set('fetchingAddFileStatus', false)
    case constants.REPLACE_FILE_CONFIRMATION:
      return state
        .set('isFileReplaceConfirmed', action.isConfirmed)
    case constants.FILE_UPLOAD_CONFIRMATION_MODAL_STATE:
      return state
        .set('isConfirmationModalVisible', action?.status)
    case constants.GENERATE_CONFIG_FILE_SUCCESS:
      return state
        .set('isConfigFileGenerated', true)
    case constants.GENERATE_CONFIG_FILE_FAILURE:
      return state
        .set('genConfigFileError', action.status)
    case constants.FETCH_DOCUMENT_TYPE_ID_SUCCESS:
      return state
        .set('documentTypeObj', action.data)
    case constants.FETCH_DOCUMENT_TYPE_ID_FAILURE:
      return state
        .set('documentTypeObj', {})
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.fileManagement

export const selectors = {
  fetching: createSelector(getState, (state) => {
    return state.get('fetching')
  }),
  fetchError: createSelector(getState, (state) => {
    return state.get('fetchError')
  }),
  openUploadFileForm: createSelector(getState, (state) =>
    state.get('openUploadFileForm')
  ),
  documentTypes: createSelector(getState, (state) =>
    state.get('documentTypes')
  ),
  devicedocumentTypes: createSelector(getState, (state) =>
    state.get('devicedocumentTypes')
  ),
  uploaddocumentTypes: createSelector(getState, (state) =>
    state.get('uploaddocumentTypes')
  ),
  uploading: createSelector(getState, (state) =>
    state.get('uploading')
  ),
  downloadError: createSelector(getState, (state) =>
    state.get('downloadError')
  ),
  showDeviceTypeFields: createSelector(getState, (state) =>
    state.get('showDeviceTypeFields')
  ),
  deviceTypes: createSelector(getState, (state) =>
    state.get('deviceTypes')
  ),
  docTypefilterCategories: createSelector(getState, (state) => {
    return state.get('docTypefilterCategories')
  }),
  deviceTypefilterCategories: createSelector(getState, (state) =>
    state.get('deviceTypefilterCategories')
  ),
  fetchFilterError: createSelector(getState, (state) => {
    return state.get('fetchFilterError')
  }),
  fetchingFilters: createSelector(getState, (state) => {
    return state.get('fetchingFilters')
  }),
  editFileSuccess: createSelector(getState, (state) => {
    return state.get('editFileSuccess')
  }),
  getAllLanguages: createSelector(getState, (state) =>
    state.get('languages')
  ),
  getValidFileTypes: createSelector(getState, (state) =>
    state.get('validFileTypes')
  ),
  fileFetching: createSelector(getState, (state) =>
    state.get('fileFetching')
  ),
  addEditFileStatus: createSelector(getState, (state) =>
    state.get('addEditFileStatus')
  ),
  deleteFileSuccess: createSelector(getState, (state) =>
    state.get('deleteFileSuccess')
  ),
  deleteFileFailure: createSelector(getState, (state) =>
    state.get('deleteFileFailure')
  ),
  fileUploadData: createSelector(getState, (state) =>
    state.get('fileUploadData')
  ),
  isFileReplaceConfirmed: createSelector(getState, (state) =>
    state.get('isFileReplaceConfirmed')
  ),
  sessionCreated: createSelector(getState, (state) =>
    state.get('sessionCreated')
  ),
  isDuplicateFile: createSelector(getState, (state) =>
    state.get('isDuplicateFile')
  ),
  fetchingAddFileStatus: createSelector(getState, (state) =>
    state.get('fetchingAddFileStatus')
  ),
  isConfirmationModalVisible: createSelector(getState, (state) => {
    return state.get('isConfirmationModalVisible')
  }),
  deletedFileName: createSelector(getState, (state) => {
    return state.get('deletedFileName')
  }),
  isConfigFileGenerated: createSelector(getState, (state) => {
    return state.get('isConfigFileGenerated')
  }),
  genConfigFileError: createSelector(getState, (state) => {
    return state.get('genConfigFileError')
  }),
  documentTypeObj: createSelector(getState, (state) => {
    return state.get('documentTypeObj')
  })
}

// helper functions

function formatDeviceTypeFilterCategories(deviceTypesFilters) {
  let deviceTypefilterCategories = []
  let categoryToAdd = { categoryType: 'deviceTypeId' }
  categoryToAdd.categoryValues = deviceTypesFilters.map(val => ({
    name: val.deviceType,
    value: `${categoryToAdd.categoryType}.${val.deviceTypeId}`
  }))
  deviceTypefilterCategories.push(categoryToAdd)
  return deviceTypefilterCategories
}

function formatDocTypeFilterCategories(documentTypesFilters) {
  let docTypefilterCategories = []
  let categoryToAdd = { categoryType: 'documentTypeName' }
  categoryToAdd.categoryValues = documentTypesFilters.map(val => ({
    name: val.documentTypeName,
    value: `${categoryToAdd.categoryType}.${val.documentTypeName}`
  }))
  docTypefilterCategories.push(categoryToAdd)
  return docTypefilterCategories
}